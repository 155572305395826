// ** React Imports
import React, { useEffect, useState } from 'react'

// ** Component Imports
import Input from "./Input"
import Calendar from 'react-calendar'

// ** Validation Imports
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'

import classnames from "classnames"
import { legend_options, RESERVATION_TYPES, schema, vehicles, services } from "../data/reservation"

const url = /*"http://192.168.137.230:5000/api/billing/reservation"*/"https://app.anasap.cz/api/billing/reservation"

const Reservation = (props) => {
    const { progress, setProgress } = props

    const [stations, setStations] = useState([])
    const [selectedStation, setSelectedStation] = useState()
    const [selectedVehicle, setSelectedVehicle] = useState()
    const [selectedService, setSelectedService] = useState()
    const [selectedTime, setSelectedTime] = useState()
    const [date, setDate] = useState(new Date())
    const [note, setNote] = useState()
    const [times, setTimes] = useState({})

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    })

    const onSubmit = data => {
        const [hours, minutes] = selectedTime.split(":")

        date.setHours(hours)
        date.setMinutes(minutes)
        date.setSeconds(0)

        fetch(url, {
            method: "POST",
            body: JSON.stringify({ ...data, stationid: selectedStation, service: selectedService, vehicle: selectedVehicle, note, date }),
            headers: {
                "Content-Type": 'application/json'    
            }
        }).then((response) => {
            if (response.ok)
            {
                setProgress(RESERVATION_TYPES.THANK_YOU)
                return reset()
            }

            throw response
        }).catch((err) => {
            alert("Došlo k chybě při odeslání rezervace. Obnovte, prosím, stránku a zkuste to znovu.")
        })
    }

    //console.log(times)

    useEffect(() => {
        if (progress === RESERVATION_TYPES.CLOSED)
        {
            setSelectedStation()
            setSelectedVehicle()
            setSelectedService()
        }
    }, [progress])

    useEffect(() => {
        setTimes(stations.filter(station => station.stationid === selectedStation)[0]?.times)
    }, [selectedStation, stations])
    
    useEffect(() => {
        if (progress === RESERVATION_TYPES.CLOSED || progress === RESERVATION_TYPES.FIRST_STEP)
        {
            fetch(url).then((res) => res.json()).then((body) => {
                setStations(body)
            }).catch((e) => {
                console.log(e)
            })
        }
    }, [progress])

    if (progress === RESERVATION_TYPES.CLOSED)
    {
        return null
    }

    const parseOpeningTime = (timeString) => {
        return timeString.substring(0, timeString.length - 3)
    }

    //INSERT INTO `customers` (`customerid`, `stationid`, `accountid`, `invoiceid`, `contactid`, `reservation`, `company_name`, `ico`, `dic`, `street`, `invoice_street`, `city`, `invoice_city`, `zip`, `invoice_zip`, `country`, `invoice_country`, `registered`, `archived`) VALUES (NULL, '0', '0', NULL, NULL, '1', 'REZERVAČNÍ SYSTÉM', NULL, NULL, NULL, NULL, NULL, NULL, NULL, NULL, NULL, NULL, '0', '0');

    const getModalBody = () => {
        if (progress === RESERVATION_TYPES.FIRST_STEP)
        {
            return (
                <>
                    <h2 className="text-center">Zvolte autoservis</h2>
                    <div className="modal-body">
                        {stations.length ? (
                            <div className="checkable-option-container">
                                {stations.map((station, i) => (
                                    <>
                                        <div className='checkable-option p-3' onClick={() => setSelectedStation(station.stationid)}>
                                            <div className="row">
                                                <div className="col-2 d-flex align-items-center justify-content-center">
                                                    <input checked={selectedStation === station.stationid} name="selected_station" id={station.company_name} type="radio"/>
                                                </div>
                                                <div className="col">
                                                    <span className="fw-bold">{station.company_name}</span><br/>
                                                    <span>{parseOpeningTime(station.openingFrom)} - {parseOpeningTime(station.openingTo)} po-pá</span><br/>
                                                    <span>{station.city} | {station.street}</span>
                                                </div>
                                            </div>
                                        </div>
                                        {(i !== stations.length - 1) ? (<hr className="m-auto"/>) : null}
                                    </>
                                ))}
                            </div>
                        ) : (<p className="mb-0 fw-bold text-center">Momentálně žádný servis nenabízí rezervaci.</p>)}
                    </div>
                    <div className="modal-footer justify-content-between">
                        <a className="fw-bold" href="#" onClick={() => setProgress(RESERVATION_TYPES.CLOSED)}>Zavřít</a>
                        <button disabled={selectedStation === undefined} onClick={() => setProgress(RESERVATION_TYPES.SECOND_STEP)} type="button" className="btn btn-orange no-shadow p-3 px-4">Pokračovat</button>
                    </div>
                </>
            )
        }
        else if (progress === RESERVATION_TYPES.SECOND_STEP)
        {
            const getDate = (date) => {
                return `${String(date.getDate()).padStart(2, "0")}.${String(date.getMonth() + 1).padStart(2, "0")}.${date.getFullYear()}`
            }

            console.log(selectedTime, date)

            return (
                <>
                    <h5 className="text-center">Vyberte vhodný termín</h5>
                    <div className="modal-body">
                        <div className="calendar-container">
                            <div className="legend row mx-auto">
                                {legend_options.map((legend, i) => (
                                    <div className="col-12 col-sm-6 d-flex mb-3">
                                        <div style={{ background: legend.color }} className={classnames(["box me-2"], { "ms-auto": (i + 1) === legend_options.length })}></div>
                                        <span className="fw-bold">
                                            {legend.title}
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <Calendar
                                className="mt-2 mx-auto"
                                onChange={setDate}
                                value={date}
                                minDate={new Date()}
                                tileDisabled={({ date }) => !times[getDate(date)].length}
                            />
                            <select value={selectedTime} class="form-select mt-3 mx-auto" onChange={(e) => setSelectedTime(e.target.value)}>
                                <option hidden selected disabled value>Vyberte čas</option>
                                {times[getDate(date)]?.map(freeTime => (
                                    <option value={freeTime}>{freeTime}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-between">
                        <a className="fw-bold" href="#" onClick={() => setProgress(RESERVATION_TYPES.FIRST_STEP)}>Zpět</a>
                        <button onClick={() => setProgress(RESERVATION_TYPES.THIRD_STEP)} disabled={selectedTime === undefined || date === undefined} type="button" className="btn btn-orange no-shadow p-3 px-4">Pokračovat</button>
                    </div>
                </>
            )
        }
        else if (progress === RESERVATION_TYPES.THIRD_STEP)
        {
            return (
                <>
                    <h5 className="text-center">Vyberte typ vozidla a službu</h5>
                    <div className="modal-body">
                        <div className="mx-5">
                            <div className="vehicles-container">
                                <h6>Typ vozidla</h6>
                                <div className="checkable-option-container row ">
                                    {vehicles.map((vehicle, i) => (
                                        <>
                                            <div className='checkable-option p-3' onClick={() => setSelectedVehicle(vehicle.id)}>
                                                <div className="row">
                                                    <div className="col-2 d-flex align-items-center justify-content-center">
                                                        <input checked={selectedVehicle === vehicle.id} name="selected_vehicle" id={vehicle.id} type="radio"/>
                                                    </div>
                                                    <div className="col">
                                                        <span className="fw-bold">{vehicle.title}</span><br/>
                                                    </div>
                                                </div>
                                            </div>
                                            {(i !== vehicles.length - 1) ? (<hr className="m-auto"/>) : null}
                                        </>
                                    ))}
                                </div>
                            </div>
                            <div className="services-container">
                                <h6 className="mt-4">Typ služby</h6>
                                <div className="checkable-option-container row">
                                    {services.map((service, i) => (
                                        <>
                                            <div className='checkable-option p-3' onClick={() => setSelectedService(service.id)}>
                                                <div className="row">
                                                    <div className="col-2 d-flex align-items-center justify-content-center">
                                                        <input checked={selectedService === service.id} name="selected_service" id={service.id} type="radio"/>
                                                    </div>
                                                    <div className="col">
                                                        <span className="fw-bold">{service.title}</span><br/>
                                                    </div>
                                                </div>
                                            </div>
                                            {(i !== services.length - 1) ? (<hr className="m-auto"/>) : null}
                                        </>
                                    ))}
                                </div>
                                <p className="mb-0 mt-1 fw-bold text-center">Pokud požadujete více než jeden servisní úkon, volejte přímo servis.</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-between">
                        <a className="fw-bold" href="#" onClick={() => setProgress(RESERVATION_TYPES.SECOND_STEP)}>Zpět</a>
                        <button onClick={() => setProgress(RESERVATION_TYPES.ORDER)} disabled={selectedVehicle === undefined || selectedService === undefined} type="button" className="btn btn-orange no-shadow p-3 px-4">Pokračovat</button>
                    </div>
                </>
            )
        }
        else if (progress === RESERVATION_TYPES.ORDER)
        {
            return (
                <>
                        <h5 className="text-center">Rezervační údaje</h5>
                        <div className="modal-body">
                            <div className="vehicles-container">
                                <div className="row mx-5">
                                    <div class="col-12 mb-3">
                                        <Input
                                            disabled
                                            label="Termín"
                                            value={`${date.toLocaleDateString()} ${selectedTime}`}
                                            type="text"
                                            name="date"
                                        />
                                    </div>
                                    <div class="col-12 mb-3">
                                        <Input
                                            label="SPZ"
                                            placeholder="1A1 1234"
                                            type="text"
                                            innerRef={register("spz")}
                                            name="spz"
                                            invalid={errors.spz}
                                            error={errors?.spz?.message}
                                        />
                                    </div>
                                    <div class="col-12 mb-3">
                                        <Input
                                            label="Telefon"
                                            placeholder="775 666 525"
                                            type="text"
                                            innerRef={register("phone")}
                                            name="phone"
                                            invalid={errors.phone}
                                            error={errors?.phone?.message}
                                        />
                                    </div>
                                    <div class="col-12 mb-3">
                                        <Input
                                            label="E-mailová adresa"
                                            placeholder="obchod@anafra.cz"
                                            type="text"
                                            innerRef={register("email")}
                                            name="email"
                                            invalid={errors.email}
                                            error={errors?.email?.message}
                                        />
                                    </div>
                                    <div class="col-12 mb-3">
                                        <Input
                                            label="Vzkaz pro servis"
                                            rows={3}
                                            type="textarea"
                                            name="note"
                                            onChange={(e) => setNote(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <a className="fw-bold" href="#" onClick={() => setProgress(RESERVATION_TYPES.THIRD_STEP)}>Zpět</a>
                            <button type="submit" className="btn btn-orange no-shadow p-3 px-4">Rezervovat</button>
                        </div>
                </>
            )
        }
        else if (progress === RESERVATION_TYPES.THANK_YOU)
        {
            return (
                <>
                    <h5 className="text-center">Vaše rezervace byla úspěšně zaznamenána!</h5>
                    <div className="modal-body text-center">
                        <p>Autoservis Vás brzy kontaktuje a domluví s Vámi další postup.</p>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button onClick={() => setProgress(RESERVATION_TYPES.CLOSED)} type="button" className="btn btn-orange no-shadow p-3 px-4">Zavřít</button>
                    </div>
                </>
            )
        }
    }

    return (
        <>
            {progress === RESERVATION_TYPES.ORDER ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="modal fade show d-block" tabIndex="-1">
                        <div className="modal-dialog modal-dialog-scrollable modal-lg">
                            <div className="modal-content pink p-4">
                                {getModalBody()}
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop show fade"></div>
                </form>
            ) : (
                <>
                    <div className="modal fade show d-block" tabIndex="-1">
                        <div className="modal-dialog modal-dialog-scrollable modal-lg">
                            <div className="modal-content pink p-4">
                                <div onClick={() => setProgress(RESERVATION_TYPES.CLOSED)} class="btn-close" data-bs-dismiss="modal" aria-label="Zavřít"></div>
                                {getModalBody()}
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop show fade"></div>
                </>
            )}
        </>
    )
}

export default Reservation