import Button from "./Button"
import Devices from "../images/devices.webp"

const SmallInfo = () => {
    return (
        <>
            <div className="devices p-4 py-5 p-lg-5">
                <h2 className="text-center mb-5">ANASAP lze využít na jakémkoliv zařízení</h2>
                <img src={Devices} alt=""/>
                <p className="text-center m-4">
                    <Button href="#cenik">Ceník</Button>
                </p>
            </div>
            <div className="video p-4 py-5 p-lg-5 text-center">
                <h2 className="mb-5" id="jak-aplikace-vypada">Jak aplikace vypadá</h2>
                <div className="video-container mx-0 mx-md-3 mx-lg-5">
                    <iframe title="Video zobrazující práci se systémem ANASAP" src="https://www.youtube.com/embed/X_L4dn2Z3wM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                <p className="mt-5">
                    <Button href="#cenik">Ceník</Button>
                </p>
            </div>
        </>
    )
}

export default SmallInfo
