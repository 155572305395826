import features from "../data/features"
import Button from "./Button"

const Features = () => {
    return (
        <div className="features p-3 p-sm-4">
            <h2 className="text-center mb-4 mb-md-0 p-sm-3 p-md-5" id="proc-anasap-pouzivat">Proč ANASAP používat?</h2>
            <div className="row">
                {features.map((feature) => (
                    <div className="col-12 col-xl-6 mb-4 mb-lg-3" key={feature.title}>
                        <div className="row content">
                            <div className="image col-12 col-sm-5 col-md-4 mb-3 mb-md-0">
                                <img src={require("../images/icons/" + feature.img).default} alt="Feature"/>
                            </div>
                            <div className="col-12 col-sm-7 col-md-8">
                                <h3 className="h4">{feature.title}</h3>
                                <p dangerouslySetInnerHTML={{ __html: feature.content }}/>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <p className="text-center m-4">
                <Button href="#cenik">Ceník</Button>
            </p>
        </div>
    )
}

export default Features