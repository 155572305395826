import React from 'react';
import Logo from "./images/logo-anasap.svg"
import "./styles/header.scss"

let menu = [
    {
        "title": "Proč ANASAP používat?",
        "slug": "#proc-anasap-pouzivat"
    },
    {
        "title": "Jak aplikace vypadá",
        "slug": "#jak-aplikace-vypada"
    },
    {
        "title": "Ceník",
        "slug": "#cenik"
    },
    {
        "title": "Kontakty",
        "slug": "#kontakty"
    },
    {
        "title": "Přihlášení",
        "slug": "https://app.anasap.cz/#/login"
    }
]

const RESERVATION_TYPES = {
    CLOSED: 0,
    FIRST_STEP: 1
}

const Header = (props) => {

    const mobileMenu = {
        "button": React.useRef(null),
        "menu": React.useRef(null),
        "isOpened": false
    }

    const toggleMenu = () => {
        let menu = mobileMenu.menu.current
        menu.classList.toggle("d-none")
    }

    const menuElements = menu.map((menuItem) => (
        <a className="header-item p-3" href={menuItem.slug} key={menuItem.slug}>
            <div>
                {menuItem.title}
            </div>
        </a>
    ))

    return (
        <header>
            <div className="container-lg p-3 ps-md-4 ps-lg-5">
                <div className="d-flex align-items-center">
                    <div className="header-logo me-auto">
                        <img src={Logo} alt="ANASAP Logo"/>
                    </div>
                    <div className="d-flex align-items-center justify-content-center d-none d-xl-flex">
                        <a className="header-item p-3 py-2 d-none" href="#rezervace" onClick={() => props.setReservationState(RESERVATION_TYPES.FIRST_STEP)}>
                            <div>Rezervovat termín</div>
                        </a>
                        {menuElements}
                    </div>
                    <div className="d-flex d-xl-none">
                        <nav className="menu-button navbar navbar-light">
                            <button aria-label="Zobrazit menu" className="navbar-toggler" type="button" ref={mobileMenu.button} onClick={toggleMenu}>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </nav>
                    </div>
                </div>
                <div className="mobile-menu d-flex align-items-center d-none d-xl-none" ref={mobileMenu.menu}>
                    <a class="header-item p-3" href="#rezervace"><div>Rezervovat termín</div></a>
                    {menuElements}
                </div>
            </div>
        </header>
    );
}

export default Header;
