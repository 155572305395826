import classnames from "classnames"

const Input = (props) => {
    const { type, innerRef, name, invalid, error, placeholder, label, required, append, onChange, value } = props

    const input = type === "textarea" ? (
        <textarea value={value} id={name} placeholder={placeholder} rows={props.rows} className={classnames("form-control", { "is-invalid": invalid && true })} {...innerRef} onChange={(e) => { onChange && onChange(e); innerRef?.onChange && innerRef?.onChange(e) }} type={type}></textarea>
    ) : (
        <input value={value} id={name} placeholder={placeholder} className={classnames("form-control", { "is-invalid": invalid && true })} {...innerRef} onChange={(e) => { onChange && onChange(e); innerRef?.onChange && innerRef?.onChange(e) }} type={type}/>
    )

    return (
        <div>
            <label for={name} class="form-label">{label}{required ? (<span class="text-danger">*</span>) : null}</label>
            <div className={classnames({ "input-group": append !== undefined })}>
                {input}
                {append !== undefined ? (<div class="input-group-text">{append}</div>) : null}
                <div class="invalid-feedback">
                    {error}
                </div>
            </div>
        </div>
    )
}

export default Input