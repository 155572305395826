import React, { useState } from 'react';

import Header from "./Header"
import Showcase from "./components/Showcase"
import Features from "./components/Features"
import Prices from "./components/Prices"
import Contact from "./components/Contact"
import SmallInfo from "./components/SmallInfo"

import "./styles/body.scss"
import 'react-calendar/dist/Calendar.css';
import Reservation from './components/Reservation';

const RESERVATION_TYPES = {
    CLOSED: 0,
    FIRST_STEP: 1,
    SECOND_STEP: 2,
    THIRD_STEP: 3,
    ORDER: 4,
    THANK_YOU: 5
}

function Body() {
    const [reservationProgress, setReservationProgress] = useState(RESERVATION_TYPES.CLOSED)
    
    return (
        <>
            <Header setReservationState={setReservationProgress}/>
            <main>
                <div className="container-lg p-0 pb-md-0 p-lg-4 pb-lg-0 p-lg-5">
                    <Showcase/>
                    <Features/>
                    <SmallInfo/>
                    <Prices/>
                    <Contact/>
                    <Reservation
                        progress={reservationProgress}
                        setProgress={setReservationProgress}
                    />
                </div>
            </main>
        </>
    );
}

export default Body;
