import prices from "../data/prices"
import Button from "./Button"

const Prices = () => {
    return (
        <div className="prices p-2 py-5 p-lg-5" id="cenik">
            <h2>Ceník</h2>
            <p className="px-3 px-lg-5 mb-5">Aplikace je možné využívat do 25 evidovaných vozů zdarma. Nad 25 evidovaných vozů je aplikace zpoplatněna. Úhrada probíhá převodem na základě faktury se splatností 14 dnů. Faktura Vám bude zaslána na email na začátku platebního období. Není nutné zadávat platební kartu. Uvedené ceny jsou s DPH. V případě nezaplacení faktury do 10 dnů po splatnosti, je ANAFRA oprávněná znemožnit přístup do aplikace.</p>
            <div className="d-flex align-items-center justify-content-center prices-container">
                {prices.map((variant, index) => (
                    <div className={`price-container ${index === 1 ? "py-5 " : ""}p-4 mb-3`} key={variant.title}>
                        <h2>{variant.title}</h2>
                        <h3 className="h1 mt-4">{variant.price === 0 ? "Zdarma" : variant.price + " Kč"}</h3>
                        <ul className="mt-5">
                            {variant.reasons.map((reason) => (
                                <li key={reason}>{reason}</li>
                            ))}
                            {variant.reasons.length === 2 ? (<li className="empty"></li>) : ""}
                        </ul>
                        <p className="mt-4">
                            <Button href="https://app.anasap.cz/#/register">{variant.trial === true ? "Vyzkoušet zdarma" : "Registrovat se"}</Button>
                        </p>
                        {variant?.flag === true ? (<div className="flag"><span>TOP</span></div>) : (<></>)}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Prices