import '../styles/cookies-consent.scss'
// noinspection ES6CheckImport
import {CookieBanner} from "@palmabit/react-cookie-law";
import React from "react";

function CookiesConsent() {
    return (
        <CookieBanner
            message="Používáme soubory cookie. Pokud je to za Vás v pořádku, klikněte na „Přijmout vše“. Kliknutím na „Nastavení“ si také můžete vybrat, které soubory cookies chcete."
            acceptButtonText="Příjmout vše"
            managePreferencesButtonText="Nastavení"
            privacyPolicyLinkText=""
            necessaryOptionText="Nutné"
            preferencesOptionText="Přizpůsobení"
            statisticsOptionText="Statistické"
            marketingOptionText="Marketingové"
            savePreferencesButtonText="Uložit"
            wholeDomain={true}
            onAccept={() => {}}
            onAcceptPreferences={() => {}}
            onAcceptStatistics={() => {}}
            onAcceptMarketing={() => {}}
        />
    )
}

export default CookiesConsent;
