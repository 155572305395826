import CarTroubles from "../images/car-troubles.webp"
import Button from "./Button"

const Showcase = () => {
    return (
        <div className="showcase mb-5 p-3 pt-4 p-md-4 p-lg-0">
            <div className="row">
                <div className="col-12 col-md-6 showcase-content d-flex align-items-center">
                    <div>
                        <h1 className="mb-3">Aplikace pro autoservisy</h1>
                        <p>Již nikdy nebudete muset řešit, kdy jste co měnili, kdy máte koho objednat a zdlouhavě evidovat servisní úkony či zákazníky.</p>
                        <Button class="mb-4 mt-md-3 mt-lg-5" href="#proc-anasap-pouzivat">Proč ANASAP používat?</Button>
                        <br/>
                        <Button href="#cenik">Používejte zdarma</Button>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <img className="mt-5 mt-md-0" src={CarTroubles} alt="Průběh práce mechanika"/>
                </div>
            </div>
        </div>
    )
}

export default Showcase