import Body from "./Body"

import "bootstrap/dist/js/bootstrap.min.js"
import CookiesConsent from "./components/CookiesConsent";
import React from "react";

function App() {
    return (
        <>
            <Body/>
            <CookiesConsent/>
        </>
    );
}

export default App;
