const prices = [
    {
        "title": "ANASAPZ",
        "price": 0,
        "reasons": [
            "Evidence max. 25 vozidel", "Časové období používání není omezeno"
        ],
        trial: true
    },
    {
        "title": "ANASAP1",
        "price": 250,
        "reasons": [
            "Neomezený počet evidovaných vozů", "Předplatné na 1 měsíc"
        ],
    },
    {
        "title": "ANASAP12",
        "price": 2799,
        "reasons": [
            "Neomezený počet evidovaných vozů", "Předplatné na 1 rok", "Cena za 1 měsíc - 233 Kč"
        ]
    }
]

export default prices