import Gabriela from "../images/gabriela.webp"

const Contact = () => {
    return (
        <div className="contact p-4 py-5 p-lg-5 text-center">
            <h2 className="mb-5" id="kontakty">Kontakty</h2>
            <div className="row">
                <div className="col-12 col-md-6 mb-4 mb-md-0">
                    <p><img src={Gabriela} alt="Fotografie"/></p>
                    <p className="mb-0 h6 fw-bold">Gabriela Chumchalová</p>
                    <p>obchod / technická podpora<br/>+420 773 745 727<br/><a href="mailto:gabriela.chumchalova@anafra.cz">sales@anasap.cz</a></p>
                </div>
                <div className="col-12 col-md-6">
                    <h2 className="h5">ANAFRA s.r.o.</h2>
                    <p>5. května 1109/63, 140 00 Praha 4 - Nusle</p>
                    <p><b>IČO:</b> 26878291, <b>DIČ:</b> CZ26878291<br/>
                        Společnost je vedená u Městského soudu v Praze, oddíl C, spisová značka 250434<br/>
                        ID datové schránky: vawmd6h
                    </p>
                    <div className="row">
                        <div className="col-12 col-md-6 text-md-end">
                            <p>
                                5. května 1109/63,<br/>
                                140 00 Praha 4 - Nusle
                            </p>
                        </div>
                        <div className="col-12 col-md-6 text-md-start">
                            <p>
                                Slezská 1281,<br/>
                                756 61 Rožnov pod Radhoštěm
                            </p>
                        </div>
                        <div className="col-12 text-center">
                            <p>
                                Pondělí - Pátek 9.00 - 16.00 h
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact