const features = [
    {
        "title": "Na míru malým autoservisům",
        "content": "Umožňuje malým servisům evidovat zákazníky a to soukromé i firemní, vozidla a knim jednotlivé úkony. Již nikdy nebudete muset řešit, co a kdy se na daném voze dělalo. A zároveň nebudete muset opakovaně po zákaznících požadovat VIN vozu.",
        "img": "1.webp"
    },
    {
        "title": "Svoboda při pořizování úkonů",
        "content": "Úkony se rozdělují na konkrétní operace s časovou náročností. Úkony můžou a nemusí být navázány na fakturaci. Možnost tisku zakázkového listu. Vše je velmi jednoduché a přehledné.",
        "img": "4.webp"
    },
    {
        "title": "Plánování a rezervace úkonů",
        "content": "Zákazník má možnost si rezervovat termín opravy přímo do svého servisu. Program pracovníka servisu upozorňuje na plánované a nově rezervované úkony. Program upozorní i na nutnost kontroly STK.",
        "img": "2.webp"
    },
    {
        "title": "Přístup pro zákazníky",
        "content": "Majitel vozidla má možnost přístupu do aplikace, ve které vidí informace o svém vozidle nebo vozidlech. Má jednoduchý přehled, co se na vozidle v minulosti dělalo, kdy je potřeba provést další úkony a kontrolu STK.",
        "img": "5.webp"
    },
    {
        "title": "Jednoduchost",
        "content": "Používání aplikace je velmi jednoduché a intuitivní. Je vhodná i pro uživatele, kteří nemají zkušenosti s využíváním žádných informačních systémů.",
        "img": "3.webp"
    },
    {
        "title": "Vše neomezené",
        "content": "Počet zákazníků, vozidel, úkonů i zaměstnanců. Do aplikace se dostanete odkudkoliv a z jakéhokoliv zařízení.",
        "img": "6.webp"
    }
    ,
    {
        "title": "Ukládání dat",
        "content": "Data jsou bezpečně uložena na serveru aplikace na naší infrastruktuře v datových centrech v Česku, jsou pravidelně zálohována a spravována administrátory ANAFRA. Databáze zákazníků NEJSOU užívána společností ANAFRA k marketingovým účelům.",
        "img": "cloud.webp"
    }
    ,
    {
        "title": "Podpora",
        "content": "Všem uživatelům ANASAP je poskytována technická podpora prostřednictvím e-mailu. Obracet se na nás můžete na e-mailové adrese <a href='mailto:support@anasap.cz'>support@anasap.cz.</a>",
        "img": "podpora.webp"
    }
]

export default features
