import * as yup from "yup"

const RESERVATION_TYPES = {
    CLOSED: 0,
    FIRST_STEP: 1,
    SECOND_STEP: 2,
    THIRD_STEP: 3,
    ORDER: 4,
    THANK_YOU: 5
}

const regexPhone = /^(\+?\d{3})?\s?(\d{3})\s?(\d{3})\s?(\d{3})$/

const schema = yup.object().shape({
    spz: yup.string().test('len', 'SPZ musí být ve správném formátu', val => val.length >= 5 && val.length <= 8),
    email: yup.string().email("E-mailová adresa musí být platná.").required("Emailová adresa nesmí být prázdná."),
    phone: yup.string().matches(regexPhone, "Telefon musí odpovídat formátu telefonního čísla.")
});

const legend_options = [
    {
        title: "Obsazeno",
        color: "#ffd7d7"
    },
    {
        title: "Volno",
        color: "white"
    }
]

const vehicles = [
    {
        title: "Osobní auto",
        id: "car"
    },
    {
        title: "SUV",
        id: "suv"
    },
    {
        title: "Dodávka",
        id: "van"
    }
]

const services = [
    {
        title: "Přezutí",
        id: "tire_change"
    },
    {
        title: "Převážení",
        id: "measurement"
    },
    {
        title: "Jiné",
        id: "repair"
    }
]

export { RESERVATION_TYPES, schema, legend_options, services, vehicles }